// feature
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '@/store/index'
import { caseTags } from '@/feature/staticData'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { sortedPages } from '@/feature/sortedPages'
import { colorConfig, mixins, zIndex } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// interface
import { CaseSearchProps } from '@/components/types'

// components
import Layout from '@/components/Layout'
import { CaseCard } from '@/components/CaseCard'
import { CaseSearchAccordion } from '@/components/CaseSearchAccordion'
import { CaseSearchAccordionContainer } from '@/components/CaseSearchAccordionContainer'
import { CommonButton } from '@/components/CommonButton'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      casePages: edges {
        casePage: node {
          id
          slug
          frontmatter {
            cardSize
            caseTags
            caseType
            date(formatString: "YYYY.MM.DD")
            title
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { casePages } = props.data.allMdx
  const { sortedPagesByCardSize } = sortedPages(casePages)

  const showSelectedCaseTags = (articleCaseTags: string[]): boolean => {
    const isSelectedCaseTags = props.selectedCaseTags.length !== 0

    if (isSelectedCaseTags) {
      const selectedCaseTagsLength = props.selectedCaseTags.length

      for (let index = 0; index < selectedCaseTagsLength; index++) {
        const hasTag = articleCaseTags.includes(props.selectedCaseTags[index])

        if (!hasTag) {
          return false
        }
      }
    }
    return true
  }

  const handleSelectedCaseTags = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked: boolean = event.currentTarget.checked

    if (isChecked) {
      props.addCaseFilter(event)
    } else {
      props.removeCaseFilter(event)
    }
  }

  const resetSelectedCaseTags = () => {
    props.clearCaseFilter()
  }

  return (
    <Layout>
      <Seo page="case" />
      <Case>
        <LayoutContainer>
          <HeadingLevel1 note="works">事例紹介</HeadingLevel1>
          <CaseSearchContainer>
            <RoundedCornersContainer>
              {/* TODO: AttentionContainer を利用する形に調整予定 */}
              <CaseSearchAccordionContainer>
                <CaseSearch>
                  <CaseSearchItem type="industry">
                    <CaseSearchAccordion type="industry">
                      <CaseSearchLabelList>
                        {caseTags.industry.map(item => (
                          <li key={item}>
                            <CaseSearchLabel checked={props.selectedCaseTags.includes(item)}>
                              <Checkbox
                                onChange={event => handleSelectedCaseTags(event)}
                                type="checkbox"
                                value={item}
                                checked={props.selectedCaseTags.includes(item)}
                              />
                              <CheckIcon />
                              {item}
                            </CaseSearchLabel>
                          </li>
                        ))}
                      </CaseSearchLabelList>
                    </CaseSearchAccordion>
                  </CaseSearchItem>
                  <CaseSearchItem type="facility">
                    <CaseSearchAccordion type="facility">
                      <CaseSearchLabelList>
                        {caseTags.facility.map(item => (
                          <li key={item}>
                            <CaseSearchLabel checked={props.selectedCaseTags.includes(item)}>
                              <Checkbox
                                onChange={event => handleSelectedCaseTags(event)}
                                type="checkbox"
                                value={item}
                                checked={props.selectedCaseTags.includes(item)}
                              />
                              <CheckIcon />
                              {item}
                            </CaseSearchLabel>
                          </li>
                        ))}
                      </CaseSearchLabelList>
                    </CaseSearchAccordion>
                  </CaseSearchItem>
                  <CaseSearchItem type="item">
                    <CaseSearchAccordion type="item">
                      <CaseSearchLabelList>
                        {caseTags.item.map(item => (
                          <li key={item}>
                            <CaseSearchLabel checked={props.selectedCaseTags.includes(item)}>
                              <Checkbox
                                onChange={event => handleSelectedCaseTags(event)}
                                type="checkbox"
                                value={item}
                                checked={props.selectedCaseTags.includes(item)}
                              />
                              <CheckIcon />
                              {item}
                            </CaseSearchLabel>
                          </li>
                        ))}
                      </CaseSearchLabelList>
                    </CaseSearchAccordion>
                  </CaseSearchItem>
                  <CaseSearchItem type="original">
                    <CaseSearchAccordion type="original">
                      <CaseSearchLabelList>
                        {caseTags.original.map(item => (
                          <li key={item}>
                            <CaseSearchLabel checked={props.selectedCaseTags.includes(item)}>
                              <Checkbox
                                onChange={event => handleSelectedCaseTags(event)}
                                type="checkbox"
                                value={item}
                                checked={props.selectedCaseTags.includes(item)}
                              />
                              <CheckIcon />
                              {item}
                            </CaseSearchLabel>
                          </li>
                        ))}
                      </CaseSearchLabelList>
                    </CaseSearchAccordion>
                  </CaseSearchItem>
                </CaseSearch>
                <ResetButtonContainer>
                  <CommonButton
                    label="チェックを外す"
                    type="button"
                    size="small"
                    color="gray"
                    onClick={() => {
                      resetSelectedCaseTags()
                    }}
                  />
                </ResetButtonContainer>
              </CaseSearchAccordionContainer>
            </RoundedCornersContainer>
            {props.selectedCaseTags.length !== 0 && (
              <SelectedCaseTagDefinitionList>
                <dt>選択中：</dt>
                {props.selectedCaseTags.map(tag => (
                  <dd key={tag}>{tag}</dd>
                ))}
              </SelectedCaseTagDefinitionList>
            )}
          </CaseSearchContainer>
          <CaseCardList>
            {sortedPagesByCardSize.map(({ casePage }) => {
              return (
                showSelectedCaseTags(casePage.frontmatter.caseTags) && (
                  <CaseCardListItem cardSize={casePage.frontmatter.cardSize} key={casePage.id}>
                    <CaseCard
                      link={`/case/${casePage.slug}/`}
                      heading={casePage.frontmatter.title}
                      imgSrc={`/images/case/${casePage.slug}/1.jpg`}
                      caseType={casePage.frontmatter.caseType}
                      cardSize={casePage.frontmatter.cardSize}
                    />
                  </CaseCardListItem>
                )
              )
            })}
          </CaseCardList>
        </LayoutContainer>
      </Case>
    </Layout>
  )
}

const Case = styled.section`
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: ${zIndex.background};
    top: -8rem;
    right: -5rem;
    margin: auto;
    width: 24.4rem;
    height: 21.1rem;
    background: top / 100% no-repeat url(/images/case/icon_case-group.png);
  }

  ${breakpointUp('md')} {
    &::after {
      top: -21rem;
      left: 0;
      right: 0;
      transform: translateX(38rem);
      margin: auto;
      width: 78.8rem;
      height: 68.2rem;
    }
  }
`

const CaseHeadingButton = styled.button`
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  width: 100%;

  ${breakpointDown('sm')} {
    pointer-events: none;
  }

  ${breakpointUp('md')} {
    margin-top: -2rem;
  }
`

const CaseSearch = styled.div`
  margin-top: 1.2rem;

  ${breakpointUp('md')} {
    display: grid;
    grid-template:
      'industry facility item' auto
      'original facility item' 1fr / 1fr auto 2fr;
    gap: 4.8rem 2rem;
    margin-top: 5rem;
  }
`

const CaseSearchContainer = styled.div`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    margin-top: 6rem;
  }
`

const CaseSearchItem = styled.div<CaseSearchProps>`
  grid-area: ${({ type }) => type};

  & + & {
    ${breakpointDown('sm')} {
      margin-top: 1.8rem;
    }
  }
`

const CaseSearchLabelList = styled.ul`
  > li + li {
    margin-top: 1.6rem;

    ${breakpointUp('md')} {
      margin-top: 1rem;
    }
  }
`

type CaseSearchLabelProps = {
  checked: boolean
}

const CaseSearchLabel = styled.label<CaseSearchLabelProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 9999px;
  line-height: 1.5;
  font-weight: bold;
  letter-spacing: 0.08em;
  padding: 0.8rem 1.6rem 0.8rem 4rem;
  min-width: 12rem;
  font-size: 1.4rem;
  border: 0.2rem solid
    ${({ checked }) => (checked ? colorConfig.themeColor : colorConfig.textSubColor)};
  background-color: ${colorConfig.textSubColor};
  color: ${({ checked }) => (checked ? colorConfig.themeColor : colorConfig.textMainColor)};
  cursor: pointer;

  ${breakpointUp('md')} {
    padding: 0.8rem 1.6rem 0.8rem 4rem;
  }
`

const Checkbox = styled.input`
  // NOTE: a11y reset css より
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + span {
    background-color: ${colorConfig.themeColor};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.1rem;
      left: 50%;
      border-right: 0.2em solid ${colorConfig.textSubColor};
      border-bottom: 0.2em solid ${colorConfig.textSubColor};
      width: 40%;
      height: 67%;
      -webkit-transform: translate(-50%, 0) rotate(45deg);
      transform: translate(-50%, 0) rotate(45deg);
    }
  }
`

const CheckIcon = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1.6rem;
  margin: auto;
  border-radius: 0.2rem;
  background-color: ${colorConfig.borderColor};
  width: 1.6rem;
  height: 1.6rem;

  ${breakpointUp('md')} {
    width: 1.8rem;
    height: 1.8rem;
  }
`

const SelectedCaseTagDefinitionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6rem;

  > dt {
    font-weight: bold;
  }

  > dd {
    &:not(:last-of-type) {
      &::after {
        content: '、';
      }
    }
  }
`

const ResetButtonContainer = styled.div`
  text-align: center;
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
`

const CaseCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
  margin-top: 8rem;

  ${breakpointUp('md')} {
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    gap: ${mixins.vw({ size: 24 })};
    margin-top: 6rem;
  }

  ${breakpointUp('lg')} {
    gap: 2.4rem;
  }
`

const CaseCardListItem = styled.li<{ cardSize: string }>`
  ${({ cardSize }) =>
    cardSize === 'large'
      ? css`
          grid-row: span 2;
          grid-column: span 2;
        `
      : css`
          width: 100%;
        `}
`

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
