import { useEffect, useRef, useState, VFC } from 'react'
import styled, { css } from 'styled-components'
import { CaseSearchAccordionProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'
import { TextThemeColor } from './Utils'

interface Status {
  active: boolean
}

export const CaseSearchAccordion: VFC<CaseSearchAccordionProps> = ({ type, children }) => {
  const [isActive, setIsActive] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(0)
  const target = useRef(null)

  const caseTagLabel = {
    industry: '事業',
    facility: '施設',
    item: 'アイテム',
    original: 'オリジナル',
  }

  const handleClickAccordion = () => {
    if (isActive) {
      target.current.style.height = 0
    } else {
      target.current.style.height = `${bodyHeight}px`
    }
    setIsActive(!isActive)
  }

  useEffect(() => {
    let unmounted = false

    if (!unmounted) {
      const mediaQuery = window.matchMedia('(max-width: 767px)')

      if (!mediaQuery.matches) {
        // NOTE: PC
        setIsActive(true)
      } else {
        // NOTE: SP
        const targetHeight = target.current.childNodes[0].scrollHeight
        setBodyHeight(targetHeight)
        target.current.style.height = 0
      }

      mediaQuery.addEventListener('change', () => {
        location.reload()
      })
    }

    return () => {
      unmounted = true
    }
  }, [bodyHeight])

  return (
    <StyledAccordion>
      <AccordionButton
        active={isActive}
        onClick={() => {
          handleClickAccordion()
        }}
        aria-expanded={isActive}
      >
        <TextThemeColor>{caseTagLabel[type]}</TextThemeColor>から探す
      </AccordionButton>
      <AccordionBody active={isActive} ref={target}>
        <AccordionBodyInner type={type}>{children}</AccordionBodyInner>
      </AccordionBody>
    </StyledAccordion>
  )
}

const StyledAccordion = styled.div``

const AccordionButton = styled.button<Status>`
  display: flex;
  width: 100%;
  position: relative;
  font-weight: bold;
  /* letter-spacing: 0.08em; */

  ${breakpointUp('md')} {
    pointer-events: none;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 2rem;
    bottom: 0;
    margin: auto;
    background-color: ${colorConfig.themeColor};
    width: 2px;
    height: 1.4rem;

    ${breakpointUp('md')} {
      display: none;
    }
  }

  &::before {
    transition: transform 0.2s ease-out;
  }

  &::after {
    transform: rotate(90deg);
  }

  &[aria-expanded='true'] {
    &::before,
    &::after {
      background-color: ${colorConfig.textMainColor};
    }

    &::before {
      transition: transform 0.2s ease-out, background-color 0.1s ease;
      transform: rotate(90deg);
    }
  }
`

const AccordionBody = styled.div<Status>`
  overflow: hidden;
  transition: height 0.4s ease;
  height: 0;

  ${breakpointUp('md')} {
    height: 100%;
  }
`

const AccordionBodyInner = styled.div<Partial<CaseSearchAccordionProps>>`
  padding-top: 0.8rem;

  ${breakpointUp('md')} {
    padding-top: 1.6rem;

    ${({ type }) =>
      type === 'item'
        ? css`
            column-count: 2;
          `
        : ''}
  }
`
