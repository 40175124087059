import { useEffect, useRef, useState, VFC } from 'react'
import styled, { css } from 'styled-components'
import { CaseSearchAccordionContainerProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig, mixins } from '@/utils/styleConfig'
import { TextThemeColor } from './Utils'

interface Status {
  active: boolean
}

export const CaseSearchAccordionContainer: VFC<CaseSearchAccordionContainerProps> = ({
  children,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(0)
  const target = useRef(null)

  const handleClickAccordion = () => {
    if (isActive) {
      target.current.style.height = 0
    } else {
      target.current.style.height = `${bodyHeight}px`
    }
    setIsActive(!isActive)
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)')

    if (!mediaQuery.matches) {
      // NOTE: PC
      const targetHeight = target.current.childNodes[0].scrollHeight
      setBodyHeight(targetHeight)
      target.current.style.height = 0
    } else {
      // NOTE: SP
      setIsActive(true)
    }
  }, [bodyHeight])

  return (
    <StyledAccordion>
      <AccordionButton
        active={isActive}
        onClick={() => {
          handleClickAccordion()
        }}
        aria-expanded={isActive}
      >
        絞り込み検索
      </AccordionButton>
      <AccordionBody active={isActive} ref={target}>
        <AccordionBodyInner>{children}</AccordionBodyInner>
      </AccordionBody>
    </StyledAccordion>
  )
}

const StyledAccordion = styled.div`
  // TODO: AttentionContainer を利用する形に調整予定
  background-color: ${colorConfig.bgAttentionColor};
  padding: 1.6rem;

  ${breakpointUp('md')} {
    padding: 2rem 3rem;
  }
`

const AccordionButton = styled.button<Status>`
  display: flex;
  width: 100%;
  position: relative;
  font-size: 2rem;
  font-weight: bold;

  ${breakpointDown('sm')} {
    pointer-events: none;
  }

  ${breakpointUp('md')} {
    ${mixins.hoverText};
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 2rem;
    bottom: 0;
    margin: auto;
    background-color: ${colorConfig.themeColor};
    width: 3px;
    height: 2.4rem;

    ${breakpointDown('sm')} {
      display: none;
    }
  }

  &::before {
    transition: transform 0.2s ease-out;
  }

  &::after {
    transform: rotate(90deg);
  }

  &[aria-expanded='true'] {
    &::before,
    &::after {
      background-color: ${colorConfig.textMainColor};
    }

    &::before {
      transition: transform 0.2s ease-out, background-color 0.1s ease;
      transform: rotate(90deg);
    }
  }
`

const AccordionBody = styled.div<Status>`
  overflow: hidden;
  transition: height 0.4s ease;
  height: 0;

  ${breakpointDown('sm')} {
    height: 100%;
  }
`

const AccordionBodyInner = styled.div<Partial<CaseSearchAccordionContainerProps>>`
  padding-top: 0.8rem;

  ${breakpointDown('sm')} {
    padding-top: 1.6rem;
  }

  ${breakpointUp('md')} {
    padding-bottom: 4rem;
  }
`
